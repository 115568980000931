
// // // // import React, { useState } from 'react';
// // // // import { useNavigate, useParams,useLocation  } from 'react-router-dom';
// // // // import image1 from '../../Assest/000000D7.jpg';  // 000000D7.jpg Corrected path spelling
// // // // import image2 from '../../Assest/00000269.jpg'; // 00000269.jpg Corrected path spelling

// // // // function ImageAnalysis() {
// // // //   const [finding, setFinding] = useState('');
// // // //   const { topicId, courseId } = useParams();
// // // //   const location = useLocation();
// // // //   const navigate = useNavigate();

// // // //   // Retrieve registration_id passed from previous page
// // // //   const registrationId = location.state?.registration_id || "";

// // // //   const handleNext = () => {
// // // //     // Navigate to image reporting, passing along the registration_id via state
// // // //     navigate(
// // // //       `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
// // // //       { state: { registration_id: registrationId } }
// // // //     );
// // // //   };
// // // //   const handleInputChange = (event) => {
// // // //     setFinding(event.target.value);
// // // //   };

// // // //   return (
// // // //     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
// // // //       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

// // // //       {/* Images Section */}
// // // //       <div className="flex justify-center space-x-4 mb-6">
// // // //         <img
// // // //           src={image1}
// // // //           alt="First Analysis"
// // // //           className="w-auto max-w-xs rounded-lg shadow-lg"
// // // //         />
// // // //         <img
// // // //           src={image2}
// // // //           alt="Second Analysis"
// // // //           className="w-auto max-w-xs rounded-lg shadow-lg"
// // // //         />
// // // //       </div>

// // // //       {/* Input Field for Findings */}
// // // //       <div className="flex flex-col items-center w-full max-w-4xl px-4">
// // // //         <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
// // // //           Finding:
// // // //         </label>
// // // //         <textarea
// // // //           id="findingInput"
// // // //           value={finding}
// // // //           onChange={handleInputChange}
// // // //           placeholder="Enter your finding here"
// // // //           className="w-full h-[500px] px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// // // //           style={{ height: '200px' }}  // Disables resizing to maintain layout consistency
// // // //         />
// // // //       </div>
// // // //       <button onClick={handleNext}
// // // //       className="mt-4 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50 transition-all">
// // // //         Generate Report
// // // //       </button>
// // // //     </div>
// // // //   );
// // // // }

// // // // export default ImageAnalysis;
// // // import React, { useState } from "react";
// // // import { useNavigate, useParams, useLocation } from "react-router-dom";
// // // import { useUser } from "../../UserContext"; // Adjust the path as needed
// // // import image1 from "../../Assest/000000D7.jpg";
// // // import image2 from "../../Assest/00000269.jpg";

// // // function ImageAnalysis() {
// // //   const [finding, setFinding] = useState("");
// // //   const [impression, setImpression] = useState("");
// // //   const [selectedImage, setSelectedImage] = useState("");
  
// // //   const { topicId, courseId } = useParams();
// // //   const location = useLocation();
// // //   const navigate = useNavigate();
// // //   const { user } = useUser(); // Retrieve user details from context

// // //   // Retrieve registration_id passed from previous page
// // //   const registrationId = location.state?.registration_id || "";

// // //   const handleInputChange = (event) => {
// // //     setFinding(event.target.value);
// // //   };

// // //   const handleImpressionChange = (event) => {
// // //     setImpression(event.target.value);
// // //   };

// // //   // Toggle selection for an image; only one image can be selected.
// // //   const handleImageSelect = (imgUrl) => {
// // //     setSelectedImage((prev) => (prev === imgUrl ? "" : imgUrl));
// // //   };

// // //   // Function to send data to backend and navigate to next page
// // //   const handleSubmit = async () => {
// // //     const payload = {
// // //       user_id: user?.id || "", // include the user id
// // //       registration_id: registrationId,
// // //       finding,
// // //       impression,
// // //       selected_image: selectedImage,
// // //     };

// // //     try {
// // //       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis`, {
// // //         method: "POST",
// // //         headers: { "Content-Type": "application/json" },
// // //         body: JSON.stringify(payload),
// // //       });

// // //       if (!response.ok) {
// // //         throw new Error("Failed to submit image analysis");
// // //       }
// // //       const result = await response.json();
// // //       console.log("✅ Image Analysis Submission:", result);
// // //       alert("Submission successful!");

// // //       // Navigate to image reporting page, passing the registration_id in state
// // //       navigate(
// // //         `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
// // //         { state: { registration_id: registrationId } }
// // //       );
// // //     } catch (error) {
// // //       console.error("Error submitting image analysis:", error);
// // //       alert("Failed to submit. Please try again later.");
// // //     }
// // //   };

// // //   return (
// // //     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
// // //       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

// // //       {/* Images Section */}
// // //       <div className="flex flex-col items-center mb-6">
// // //         <div className="flex justify-center space-x-4">
// // //           <div className="flex flex-col items-center">
// // //             <img
// // //               src={image1}
// // //               alt="First Analysis"
// // //               className="w-auto max-w-xs rounded-lg shadow-lg"
// // //             />
// // //             <div className="mt-2">
// // //               <input
// // //                 type="checkbox"
// // //                 checked={selectedImage === image1}
// // //                 onChange={() => handleImageSelect(image1)}
// // //               />
// // //               <span className="ml-2">Select this image</span>
// // //             </div>
// // //           </div>
// // //           <div className="flex flex-col items-center">
// // //             <img
// // //               src={image2}
// // //               alt="Second Analysis"
// // //               className="w-auto max-w-xs rounded-lg shadow-lg"
// // //             />
// // //             <div className="mt-2">
// // //               <input
// // //                 type="checkbox"
// // //                 checked={selectedImage === image2}
// // //                 onChange={() => handleImageSelect(image2)}
// // //               />
// // //               <span className="ml-2">Select this image</span>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>

// // //       {/* Input Field for Findings */}
// // //       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
// // //         {/* <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
// // //           Finding:
// // //         </label> */}
// // //         <textarea
// // //           id="findingInput"
// // //           value={finding}
// // //           onChange={handleInputChange}
// // //           placeholder="Enter your finding here"
// // //           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// // //           style={{ height: "200px" }}
// // //         />
// // //       </div>

// // //       {/* Input Field for Impression */}
// // //       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
// // //         {/* <label htmlFor="impressionInput" className="mb-2 text-lg font-medium text-gray-700">
// // //           Impression:
// // //         </label> */}
// // //         <textarea
// // //           id="impressionInput"
// // //           value={impression}
// // //           onChange={handleImpressionChange}
// // //           placeholder="Enter your impression here"
// // //           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// // //           style={{ height: "200px" }}
// // //         />
// // //       </div>

// // //       <button
// // //         onClick={handleSubmit}
// // //         className="mt-4 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50 transition-all"
// // //       >
// // //         Generate Report
// // //       </button>
// // //     </div>
// // //   );
// // // }

// // // export default ImageAnalysis;
// // // import React, { useState } from 'react';
// // // import { useNavigate, useParams,useLocation  } from 'react-router-dom';
// // // import image1 from '../../Assest/000000D7.jpg';  // 000000D7.jpg Corrected path spelling
// // // import image2 from '../../Assest/00000269.jpg'; // 00000269.jpg Corrected path spelling

// // // function ImageAnalysis() {
// // //   const [finding, setFinding] = useState('');
// // //   const { topicId, courseId } = useParams();
// // //   const location = useLocation();
// // //   const navigate = useNavigate();

// // //   // Retrieve registration_id passed from previous page
// // //   const registrationId = location.state?.registration_id || "";

// // //   const handleNext = () => {
// // //     // Navigate to image reporting, passing along the registration_id via state
// // //     navigate(
// // //       `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
// // //       { state: { registration_id: registrationId } }
// // //     );
// // //   };
// // //   const handleInputChange = (event) => {
// // //     setFinding(event.target.value);
// // //   };

// // //   return (
// // //     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
// // //       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

// // //       {/* Images Section */}
// // //       <div className="flex justify-center space-x-4 mb-6">
// // //         <img
// // //           src={image1}
// // //           alt="First Analysis"
// // //           className="w-auto max-w-xs rounded-lg shadow-lg"
// // //         />
// // //         <img
// // //           src={image2}
// // //           alt="Second Analysis"
// // //           className="w-auto max-w-xs rounded-lg shadow-lg"
// // //         />
// // //       </div>

// // //       {/* Input Field for Findings */}
// // //       <div className="flex flex-col items-center w-full max-w-4xl px-4">
// // //         <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
// // //           Finding:
// // //         </label>
// // //         <textarea
// // //           id="findingInput"
// // //           value={finding}
// // //           onChange={handleInputChange}
// // //           placeholder="Enter your finding here"
// // //           className="w-full h-[500px] px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// // //           style={{ height: '200px' }}  // Disables resizing to maintain layout consistency
// // //         />
// // //       </div>
// // //       <button onClick={handleNext}
// // //       className="mt-4 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50 transition-all">
// // //         Generate Report
// // //       </button>
// // //     </div>
// // //   );
// // // }

// // // export default ImageAnalysis;
// // import React, { useState } from "react";
// // import { useNavigate, useParams, useLocation } from "react-router-dom";
// // import { useUser } from "../../UserContext"; // Adjust the path as needed
// // import image1 from "../../Assest/000000D7.jpg";
// // import image2 from "../../Assest/00000269.jpg";

// // function ImageAnalysis() {
// //   const [finding, setFinding] = useState("");
// //   const [impression, setImpression] = useState("");
// //   const [selectedImage, setSelectedImage] = useState("");
  
// //   const { topicId, courseId } = useParams();
// //   const location = useLocation();
// //   const navigate = useNavigate();
// //   const { user } = useUser(); // Retrieve user details from context

// //   // Retrieve registration_id passed from previous page
// //   const registrationId = location.state?.registration_id || "";

// //   const handleInputChange = (event) => {
// //     setFinding(event.target.value);
// //   };

// //   const handleImpressionChange = (event) => {
// //     setImpression(event.target.value);
// //   };

// //   // Toggle selection for an image; only one image can be selected.
// //   const handleImageSelect = (imgUrl) => {
// //     setSelectedImage((prev) => (prev === imgUrl ? "" : imgUrl));
// //   };

// //   // Function to send data to backend and navigate to next page
// //   const handleSubmit = async () => {
// //     const payload = {
// //       user_id: user?.id || "", // include the user id
// //       registration_id: registrationId,
// //       finding,
// //       impression,
// //       selected_image: selectedImage,
// //     };

// //     try {
// //       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis`, {
// //         method: "POST",
// //         headers: { "Content-Type": "application/json" },
// //         body: JSON.stringify(payload),
// //       });

// //       if (!response.ok) {
// //         throw new Error("Failed to submit image analysis");
// //       }
// //       const result = await response.json();
// //       console.log("✅ Image Analysis Submission:", result);
// //       alert("Submission successful!");

// //       // Navigate to image reporting page, passing the registration_id in state
// //       navigate(
// //         `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
// //         { state: { registration_id: registrationId } }
// //       );
// //     } catch (error) {
// //       console.error("Error submitting image analysis:", error);
// //       alert("Failed to submit. Please try again later.");
// //     }
// //   };

// //   return (
// //     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
// //       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

// //       {/* Images Section */}
// //       <div className="flex flex-col items-center mb-6">
// //         <div className="flex justify-center space-x-4">
// //           <div className="flex flex-col items-center">
// //             <img
// //               src={image1}
// //               alt="First Analysis"
// //               className="w-auto max-w-xs rounded-lg shadow-lg"
// //             />
// //             <div className="mt-2">
// //               <input
// //                 type="checkbox"
// //                 checked={selectedImage === image1}
// //                 onChange={() => handleImageSelect(image1)}
// //               />
// //               <span className="ml-2">Select this image</span>
// //             </div>
// //           </div>
// //           <div className="flex flex-col items-center">
// //             <img
// //               src={image2}
// //               alt="Second Analysis"
// //               className="w-auto max-w-xs rounded-lg shadow-lg"
// //             />
// //             <div className="mt-2">
// //               <input
// //                 type="checkbox"
// //                 checked={selectedImage === image2}
// //                 onChange={() => handleImageSelect(image2)}
// //               />
// //               <span className="ml-2">Select this image</span>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* Input Field for Findings */}
// //       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
// //         {/* <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
// //           Finding:
// //         </label> */}
// //         <textarea
// //           id="findingInput"
// //           value={finding}
// //           onChange={handleInputChange}
// //           placeholder="Enter your finding here"
// //           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// //           style={{ height: "200px" }}
// //         />
// //       </div>

// //       {/* Input Field for Impression */}
// //       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
// //         {/* <label htmlFor="impressionInput" className="mb-2 text-lg font-medium text-gray-700">
// //           Impression:
// //         </label> */}
// //         <textarea
// //           id="impressionInput"
// //           value={impression}
// //           onChange={handleImpressionChange}
// //           placeholder="Enter your impression here"
// //           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// //           style={{ height: "200px" }}
// //         />
// //       </div>

// //       <div className="flex justify-center mt-10 mb-6">
// //         <button
// //           onClick={handleSubmit}
// //           className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
// //         >
// //           Generate Report
// //         </button>
// //       </div>
// //     </div>
// //   );
// // }

// // export default ImageAnalysis;
// // import React, { useState } from 'react';
// // import { useNavigate, useParams,useLocation  } from 'react-router-dom';
// // import image1 from '../../Assest/000000D7.jpg';  // 000000D7.jpg Corrected path spelling
// // import image2 from '../../Assest/00000269.jpg'; // 00000269.jpg Corrected path spelling

// // function ImageAnalysis() {
// //   const [finding, setFinding] = useState('');
// //   const { topicId, courseId } = useParams();
// //   const location = useLocation();
// //   const navigate = useNavigate();

// //   // Retrieve registration_id passed from previous page
// //   const registrationId = location.state?.registration_id || "";

// //   const handleNext = () => {
// //     // Navigate to image reporting, passing along the registration_id via state
// //     navigate(
// //       `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
// //       { state: { registration_id: registrationId } }
// //     );
// //   };
// //   const handleInputChange = (event) => {
// //     setFinding(event.target.value);
// //   };

// //   return (
// //     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
// //       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

// //       {/* Images Section */}
// //       <div className="flex justify-center space-x-4 mb-6">
// //         <img
// //           src={image1}
// //           alt="First Analysis"
// //           className="w-auto max-w-xs rounded-lg shadow-lg"
// //         />
// //         <img
// //           src={image2}
// //           alt="Second Analysis"
// //           className="w-auto max-w-xs rounded-lg shadow-lg"
// //         />
// //       </div>

// //       {/* Input Field for Findings */}
// //       <div className="flex flex-col items-center w-full max-w-4xl px-4">
// //         <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
// //           Finding:
// //         </label>
// //         <textarea
// //           id="findingInput"
// //           value={finding}
// //           onChange={handleInputChange}
// //           placeholder="Enter your finding here"
// //           className="w-full h-[500px] px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
// //           style={{ height: '200px' }}  // Disables resizing to maintain layout consistency
// //         />
// //       </div>
// //       <button onClick={handleNext}
// //       className="mt-4 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50 transition-all">
// //         Generate Report
// //       </button>
// //     </div>
// //   );
// // }

// // export default ImageAnalysis;
// import React, { useState } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { useUser } from "../../UserContext"; // Adjust the path as needed
// import image1 from "../../Assest/000000D7.jpg";
// import image2 from "../../Assest/00000269.jpg";

// function ImageAnalysis() {
//   const [finding, setFinding] = useState("");
//   const [impression, setImpression] = useState("");
//   const [selectedImage, setSelectedImage] = useState("");
  
//   const { topicId, courseId } = useParams();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { user } = useUser(); // Retrieve user details from context

//   // Retrieve registration_id passed from previous page
//   const registrationId = location.state?.registration_id || "";

//   const handleInputChange = (event) => {
//     setFinding(event.target.value);
//   };

//   const handleImpressionChange = (event) => {
//     setImpression(event.target.value);
//   };

//   // Toggle selection for an image; only one image can be selected.
//   const handleImageSelect = (imgUrl) => {
//     setSelectedImage((prev) => (prev === imgUrl ? "" : imgUrl));
//   };

//   // Function to send data to backend and navigate to next page
//   const handleSubmit = async () => {
//     const payload = {
//       user_id: user?.id || "", // include the user id
//       registration_id: registrationId,
//       finding,
//       impression,
//       selected_image: selectedImage,
//     };

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to submit image analysis");
//       }
//       const result = await response.json();
//       console.log("✅ Image Analysis Submission:", result);
//       alert("Submission successful!");

//       // Navigate to image reporting page, passing the registration_id in state
//       navigate(
//         `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
//         { state: { registration_id: registrationId } }
//       );
//     } catch (error) {
//       console.error("Error submitting image analysis:", error);
//       alert("Failed to submit. Please try again later.");
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
//       <h1 className="text-3xl font-bold mt-[-350px] text-gray-900 mb-6">Image Analysis</h1>

//       {/* Images Section */}
//       <div className="flex flex-col items-center mb-6">
//         <div className="flex justify-center space-x-4">
//           <div className="flex flex-col items-center">
//             <img
//               src={image1}
//               alt="First Analysis"
//               className="w-auto max-w-xs rounded-lg shadow-lg"
//             />
//             <div className="mt-2">
//               <input
//                 type="checkbox"
//                 checked={selectedImage === image1}
//                 onChange={() => handleImageSelect(image1)}
//               />
//               <span className="ml-2">Select this image</span>
//             </div>
//           </div>
//           <div className="flex flex-col items-center">
//             <img
//               src={image2}
//               alt="Second Analysis"
//               className="w-auto max-w-xs rounded-lg shadow-lg"
//             />
//             <div className="mt-2">
//               <input
//                 type="checkbox"
//                 checked={selectedImage === image2}
//                 onChange={() => handleImageSelect(image2)}
//               />
//               <span className="ml-2">Select this image</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Input Field for Findings */}
//       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
//         {/* <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
//           Finding:
//         </label> */}
//         <textarea
//           id="findingInput"
//           value={finding}
//           onChange={handleInputChange}
//           placeholder="Enter your finding here"
//           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
//           style={{ height: "200px" }}
//         />
//       </div>

//       {/* Input Field for Impression */}
//       <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
//         {/* <label htmlFor="impressionInput" className="mb-2 text-lg font-medium text-gray-700">
//           Impression:
//         </label> */}
//         <textarea
//           id="impressionInput"
//           value={impression}
//           onChange={handleImpressionChange}
//           placeholder="Enter your impression here"
//           className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
//           style={{ height: "200px" }}
//         />
//       </div>

//       <div className="flex justify-center mt-10 mb-6">
//         <button
//           onClick={handleSubmit}
//           className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
//         >
//           Generate Report
//         </button>
//       </div>
//     </div>
//   );
// }

// export default ImageAnalysis;


import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useUser } from "../../UserContext"; // Adjust the path as needed
import image1 from "../../Assest/000000D7.jpg";
import image2 from "../../Assest/00000269.jpg";

function ImageAnalysis() {
  const [finding, setFinding] = useState("");
  const [impression, setImpression] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  
  const { topicId, courseId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser(); // Retrieve user details from context

  // Retrieve registration_id passed from previous page
  const registrationId = location.state?.registration_id || "";

  const handleInputChange = (event) => {
    setFinding(event.target.value);
  };

  const handleImpressionChange = (event) => {
    setImpression(event.target.value);
  };

  // Toggle selection for an image; only one image can be selected.
  const handleImageSelect = (imgUrl) => {
    setSelectedImage((prev) => (prev === imgUrl ? "" : imgUrl));
  };

  // Function to send data to backend and navigate to next page
  const handleSubmit = async () => {
    const payload = {
      user_id: user?.id || "", // include the user id
      registration_id: registrationId,
      finding,
      impression,
      selected_image: selectedImage,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to submit image analysis");
      }
      const result = await response.json();
      console.log("✅ Image Analysis Submission:", result);
      alert("Submission successful!");

      // Navigate to image reporting page, passing the registration_id in state
      navigate(
        `/student-dashboard/courses/ongoing/${topicId}/protocols/image-reporting/${courseId}`,
        { state: { registration_id: registrationId } }
      );
    } catch (error) {
      console.error("Error submitting image analysis:", error);
      alert("Failed to submit. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-full p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-gray-900 mb-8 mt-4">Image Analysis</h1>

      {/* Images Section */}
      <div className="flex flex-col items-center mb-6">
        <div className="flex flex-wrap justify-center gap-6">
          <div className="flex flex-col items-center">
            <img
              src={image1}
              alt="First Analysis"
              className="w-auto max-w-xs rounded-lg shadow-lg"
            />
            <div className="mt-2">
              <input
                type="checkbox"
                checked={selectedImage === image1}
                onChange={() => handleImageSelect(image1)}
              />
              <span className="ml-2">Select this image</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={image2}
              alt="Second Analysis"
              className="w-auto max-w-xs rounded-lg shadow-lg"
            />
            <div className="mt-2">
              <input
                type="checkbox"
                checked={selectedImage === image2}
                onChange={() => handleImageSelect(image2)}
              />
              <span className="ml-2">Select this image</span>
            </div>
          </div>
        </div>
      </div>

      {/* Input Field for Findings */}
      <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
        {/* <label htmlFor="findingInput" className="mb-2 text-lg font-medium text-gray-700">
          Finding:
        </label> */}
        <textarea
          id="findingInput"
          value={finding}
          onChange={handleInputChange}
          placeholder="Enter your finding here"
          className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          style={{ height: "200px" }}
        />
      </div>

      {/* Input Field for Impression */}
      <div className="flex flex-col items-center w-full max-w-4xl px-4 mb-4">
        {/* <label htmlFor="impressionInput" className="mb-2 text-lg font-medium text-gray-700">
          Impression:
        </label> */}
        <textarea
          id="impressionInput"
          value={impression}
          onChange={handleImpressionChange}
          placeholder="Enter your impression here"
          className="w-full px-4 py-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          style={{ height: "200px" }}
        />
      </div>

      <div className="flex justify-center mt-6 mb-6">
        <button
          onClick={handleSubmit}
          className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
        >
          Generate Report
        </button>
      </div>
    </div>
  );
}

export default ImageAnalysis;