
// import React, { useEffect, useState, useRef } from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import ctscan from "../../Assest/00000269.jpg";
// import onesimlogo from "../../Assest/logo192.jpg";
// import { useNavigate, useParams,useLocation  } from "react-router-dom";

// const Report = () => {
//     const reportRef = useRef();
//     const buttonRef = useRef();

//     const { topicId, courseId } = useParams();
//     const navigate = useNavigate(); 
//     const location = useLocation();
//     // Retrieve registration_id passed from a previous page (if any)
//     const registrationId = location.state?.registration_id || "";

//     const [analysisData, setAnalysisData] = useState(null);
//     const [registrationData, setRegistrationData] = useState(null);

//     useEffect(() => {
//         if (registrationId) {
//           fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis/${registrationId}`)
//             .then((res) => {
//               if (!res.ok) {
//                 throw new Error("Failed to fetch image analysis data");
//               }
//               return res.json();
//             })
//             .then((data) => {
//               setAnalysisData(data);
//             })
//             .catch((error) => {
//               console.error("Error fetching analysis data:", error);
//             });
//         }
//       }, [registrationId]);

//       useEffect(() => {
//         if (registrationId) {
//           fetch(`${process.env.REACT_APP_API_URL}/api/patient-registration/${registrationId}`)
//             .then((res) => {
//               if (!res.ok) {
//                 throw new Error("Failed to fetch patient registration data");
//               }
//               return res.json();
//             })
//             .then((data) => {
//               setRegistrationData(data);
//             })
//             .catch((error) => {
//               console.error("Error fetching registration data:", error);
//             });
//         }
//       }, [registrationId]);

//     const handleDownloadPDF = () => {
//         if (buttonRef.current) buttonRef.current.style.display = "none";
       
//         html2canvas(reportRef.current, { scale: 2 }).then((canvas) => {
//             const imgData = canvas.toDataURL("image/png");
//             const pdf = new jsPDF("p", "mm", "a4");
//             const imgWidth = 210;
//             const imgHeight = (canvas.height * imgWidth) / canvas.width;

//             pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
//             pdf.save("report.pdf");

//             if (buttonRef.current) buttonRef.current.style.display = "block";
//         });
//     };

//     const handleNext = () => {
//         // Navigate to the post-counselling page and pass the registration_id in state
//         navigate(
//             `/student-dashboard/courses/ongoing/${topicId}/protocols/post-counselling/${courseId}`,
//             { state: { registration_id: registrationId } }
//         );
//     };

//     const handlePrint = () => {
//         const printContent = reportRef.current.innerHTML;
//         const originalContent = document.body.innerHTML;
        
//         document.body.innerHTML = printContent;
//         window.print();
//         document.body.innerHTML = originalContent;
//         window.location.reload();
//     };

//     const patientName = registrationData?.name || "Yashvi M Patel";
//     const patientAge = registrationData?.age || "21";
//     const patientSex = registrationData?.sex || "Female";
//     const registeredOnDynamic = registrationData?.created_at
//       ? new Date(registrationData.created_at).toLocaleString()
//       : "Time";

//     const finding = analysisData?.finding || "Default Finding text.";
//     const impression = analysisData?.impression || "Default Impression text.";
//     const imageToShow = analysisData?.selected_image || ctscan;
//     const reportedOn = analysisData?.created_at
//     ? new Date(analysisData.created_at).toLocaleString()
//     : "Time";

//     return (
//         <div className="flex justify-center bg-gray-200 p-4 min-h-screen">
//             <div ref={reportRef} className="w-[210mm] bg-white shadow-lg p-8 rounded-lg">
//                 <div className="flex justify-between items-center mb-4">
//                     <img src={onesimlogo} className="w-32 rounded-sm object-fill h-20" alt="Logo" />
//                     <div className="text-center">
//                         <h1 className="text-3xl font-bold">ONESIM <span className="text-blue-700">CLINIC</span></h1>
//                         <h3 className="text-lg font-semibold">X-Ray | CT - Scan | MRI | USG</h3>
//                         <h6 className="text-sm">ONE SIMULATION PVT LTD. UNIT NO T4 B 611 NX ONE, HEALTHCARE COMPLEX, NOIDA - 201306</h6>
//                     </div>
//                     <div className="text-right text-sm">
//                         <h3>0123456789 | 0912345678</h3>
//                         <h3>onesimulation@gmail.com</h3>
//                     </div>
//                 </div>

//                 <div className="w-full h-6 relative bg-blue-700 text-white items-center text-right pr-2 flex ">
//                     <div className="flex absolute right-0 mr-2 " >www.onesimulation.co.in</div>
//                 </div>

//                 <div className="mt-6 grid grid-cols-3 text-sm gap-4">
//                     <div>
//                         <h2 className="font-bold text-lg">{patientName}</h2>
//                         <h3>Age: {patientAge}</h3>
//                         <h3>Sex: {patientSex}</h3>
//                     </div>
//                     <div>
//                         <h3 className="font-bold">PID: {registrationId}</h3>
//                         {/* <h3>Apt ID: 78687</h3> */}
//                         <h3>Ref. by: <span className="font-bold">Dr. Ankur Srivastava</span></h3>
//                     </div>
//                     <div>
//                         <h3><span className="font-bold">Registered on:</span>{registeredOnDynamic}</h3>
//                         <h3>
//               <span className="font-bold">Reported on:</span> {reportedOn}
//             </h3>
//                     </div>
//                 </div>
                
//                 <div className="w-full h-px bg-black my-6"></div>
//                 <h1 className="text-center text-2xl font-bold">CT SCAN</h1>

//                 <div className="mt-6 space-y-4 text-sm">
//                     <div>
//                         <h1 className="font-bold">Part</h1>
//                         <p>Brain: Plain</p>
//                     </div>
//                     <div>
//                         <h1 className="font-bold">Technique</h1>
//                         <p>Plain CT scan was carried out on a multi-detector scanner. Axial sections from the base of the skull to the cranial vault were obtained and evaluated.</p>
//                     </div>
//                     <div>
//                         <h1 className="font-bold">Findings</h1>
//                         <p>{finding}</p>
//                     </div>
//                     <div>
//                         <h1 className="font-bold">Impression</h1>
//                         <p>{impression}</p>
//                     </div>
//                 </div>

//                 <div className="mt-4 flex justify-center">
//                 <img src={imageToShow} alt="CT Scan" className="w-60 rounded-md shadow-md max-h-[250px] object-fill" />
//                 </div>

//                 <div className="w-full h-[3px] bg-gray-600 my-8"></div>

//                 <div className="flex justify-between text-center mb-10 ">
//                     {["Dr. Ankur Srivastava", "Dr. Khushboo Srivastava", "Dr. Asad Abbas"].map((name, index) => (
//                         <div key={index}>
//                             <h1 className="font-bold text-lg">{name}</h1>
//                             <h3 className="text-sm">MBBS, MD (Radiology)</h3>
//                             <h3 className="text-sm">Consultant Radiologist</h3>
//                         </div>
//                     ))}
//                 </div>

//                 <div className="w-full h-10 relative bg-blue-700 items-center text-white text-right pr-2 flex ">
//                     <div className="flex absolute right-0 mr-2 " >www.onesimulation.co.in</div>
//                 </div>

//                 <div className="flex justify-center mt-6 space-x-4">
//                     <button 
//                         ref={buttonRef} 
//                         onClick={handleDownloadPDF} 
//                         className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//                     >
//                         Download
//                     </button>
//                     <button 
//                         onClick={handlePrint} 
//                         className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-green-700"
//                     >
//                         Print Report
//                     </button>
//                     <button
//                         onClick={handleNext}
//                         className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-300"
//                     >
//                         Next Page
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Report;

import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ctscan from "../../Assest/00000269.jpg";
import onesimlogo from "../../Assest/logo192.jpg";
import { useNavigate, useParams,useLocation  } from "react-router-dom";

const Report = () => {
    const reportRef = useRef();
    const buttonRef = useRef();

    const { topicId, courseId } = useParams();
    const navigate = useNavigate(); 
    const location = useLocation();
    // Retrieve registration_id passed from a previous page (if any)
    const registrationId = location.state?.registration_id || "";

    const [analysisData, setAnalysisData] = useState(null);
    const [registrationData, setRegistrationData] = useState(null);

    useEffect(() => {
        if (registrationId) {
          fetch(`${process.env.REACT_APP_API_URL}/api/image-analysis/${registrationId}`)
            .then((res) => {
              if (!res.ok) {
                throw new Error("Failed to fetch image analysis data");
              }
              return res.json();
            })
            .then((data) => {
              setAnalysisData(data);
            })
            .catch((error) => {
              console.error("Error fetching analysis data:", error);
            });
        }
      }, [registrationId]);

      useEffect(() => {
        if (registrationId) {
          fetch(`${process.env.REACT_APP_API_URL}/api/patient-registration/${registrationId}`)
            .then((res) => {
              if (!res.ok) {
                throw new Error("Failed to fetch patient registration data");
              }
              return res.json();
            })
            .then((data) => {
              setRegistrationData(data);
            })
            .catch((error) => {
              console.error("Error fetching registration data:", error);
            });
        }
      }, [registrationId]);

    const handleDownloadPDF = () => {
        if (buttonRef.current) buttonRef.current.style.display = "none";
       
        html2canvas(reportRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save("CT_Scan_Report.pdf");
            

            if (buttonRef.current) buttonRef.current.style.display = "block";
        });
    };

    const handleNext = () => {
        // Navigate to the post-counselling page and pass the registration_id in state
        navigate(
            `/student-dashboard/courses/ongoing/${topicId}/protocols/post-counselling/${courseId}`,
            { state: { registration_id: registrationId } }
        );
    };

    const handlePrint = () => {
        const printContent = reportRef.current.innerHTML;
        const originalContent = document.body.innerHTML;
        
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    console.log("Registration DAta:", registrationData);
    const patientName = registrationData?.name || "Yashvi M Patel";
    const patientAge = registrationData?.age || "21";
    const patientSex = registrationData?.gender ;
    const registeredOnDynamic = registrationData?.created_at
      ? new Date(registrationData.created_at).toLocaleString()
      : "Time";

    const finding = analysisData?.finding || "Default Finding text.";
    const impression = analysisData?.impression || "Default Impression text.";
    const imageToShow = analysisData?.selected_image || ctscan;
    const reportedOn = analysisData?.created_at
    ? new Date(analysisData.created_at).toLocaleString()
    : "Time";

    return (
        <div className="flex justify-center bg-gray-200 p-4 min-h-screen">
            <div ref={reportRef} className="w-[210mm] bg-white shadow-lg p-8 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                    <img src={onesimlogo} className="w-32 rounded-sm object-fill h-20" alt="Logo" />
                    <div className="text-center">
                        <h1 className="text-3xl font-bold">ONESIM <span className="text-blue-700">CLINIC</span></h1>
                        <h3 className="text-lg font-semibold">X-Ray | CT - Scan | MRI | USG</h3>
                        <h6 className="text-sm">ONE SIMULATION PVT LTD. UNIT NO T4 B 611 NX ONE, HEALTHCARE COMPLEX, NOIDA - 201306</h6>
                    </div>
                    <div className="text-right text-sm">
                        <h3>0123456789 | 0912345678</h3>
                        <h3>onesimulation@gmail.com</h3>
                    </div>
                </div>

                <div className="w-full h-6 relative bg-blue-700 text-white flex justify-end  ">
                    <div className="mr-2">www.onesimulation.co.in</div>
                </div>

                <div className="mt-6 grid grid-cols-3 text-sm gap-4">
                    <div>
                        <h2 className="font-bold text-lg">{patientName}</h2>
                        <h3>Age: {patientAge}</h3>
                        <h3>Sex: {patientSex}</h3>
                    </div>
                    <div>
                        <h3 className="font-bold">PID: {registrationId}</h3>
                        {/* <h3>Apt ID: 78687</h3> */}
                        <h3>Ref. by: <span className="font-bold">Dr. Ankur Srivastava</span></h3>
                    </div>
                    <div>
                        <h3><span className="font-bold">Registered on:</span>{registeredOnDynamic}</h3>
                        <h3>
              <span className="font-bold">Reported on:</span> {reportedOn}
            </h3>
                    </div>
                </div>
                
                <div className="w-full h-px bg-black my-6"></div>
                <h1 className="text-center text-2xl font-bold">CT SCAN</h1>

                <div className="mt-6 space-y-4 text-sm">
                    <div>
                        <h1 className="font-bold">Part</h1>
                        <p>Brain: Plain</p>
                    </div>
                    <div>
                        <h1 className="font-bold">Technique</h1>
                        <p>Plain CT scan was carried out on a multi-detector scanner. Axial sections from the base of the skull to the cranial vault were obtained and evaluated.</p>
                    </div>
                    <div>
                        <h1 className="font-bold">Findings</h1>
                        <p>{finding}</p>
                    </div>
                    <div>
                        <h1 className="font-bold">Impression</h1>
                        <p>{impression}</p>
                    </div>
                </div>

                <div className="mt-4 flex justify-center">
                <img src={imageToShow} alt="CT Scan" className="w-60 rounded-md shadow-md max-h-[250px] object-fill" />
                </div>

                <div className="w-full h-[3px] bg-gray-600 my-8"></div>

                <div className="flex justify-between text-center mb-10 ">
                    {["Dr. Ankur Srivastava", "Dr. Khushboo Srivastava", "Dr. Asad Abbas"].map((name, index) => (
                        <div key={index}>
                            <h1 className="font-bold text-lg">{name}</h1>
                            <h3 className="text-sm">MBBS, MD (Radiology)</h3>
                            <h3 className="text-sm">Consultant Radiologist</h3>
                        </div>
                    ))}
                </div>

                <div className="w-full h-10 relative bg-blue-700 items-center text-white text-right pr-2 flex ">
                    <div className="flex absolute right-0 mr-2 " >www.onesimulation.co.in</div>
                </div>

                <div className="flex justify-center mt-10 mb-6 space-x-4">
                    <button 
                        ref={buttonRef} 
                        onClick={handleDownloadPDF} 
                        className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
                    >
                        Download
                    </button>
                    <button 
                        onClick={handlePrint} 
                        className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
                    >
                        Print Report
                    </button>
                    <button
                        onClick={handleNext}
                        className="w-40 py-3 rounded-lg text-base font-medium transition duration-200 shadow-md bg-blue-600 text-white hover:bg-blue-700"
                    >
                        Next →
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Report;