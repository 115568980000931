// import React, { useEffect, useState } from 'react';
// import UserDetails from './UserDetails';
// import { FaUserCircle } from "react-icons/fa";

// const TeacherHome = () => {
//     const [user, setUser] = useState(null);

//     useEffect(() => {
//         const storedUser = JSON.parse(localStorage.getItem('user'));
//         setUser(storedUser);
//     }, []);

//     return (
//         <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
//             {user ? (
//                 <>
//                     <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl mb-8">
//                         <div className="flex flex-col items-center mb-8">
//                             <div className="bg-blue-600 text-white p-3 rounded-full mb-4">
//                                 <FaUserCircle className="text-7xl drop-shadow-md" />
//                             </div>
//                             <div className="bg-gradient-to-r  from-blue-500 to-purple-500 text-white px-8 py-3 rounded-lg shadow-md">
//                                 <h1 className="text-3xl font-bold">
//                                     Welcome, {user.name}!
//                                 </h1>
//                                 <p className="text-lg text-center mt-1">
//                                     {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
//                                 </p>
//                             </div>
//                         </div>
                        
//                         <UserDetails user={user} />
//                     </div>

//                     {/* <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
//                         <RecentActivity />
//                     </div> */}
//                 </>
//             ) : (
//                 <div className="text-center bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
//                     <h1 className="text-4xl font-bold text-red-500 mb-4">Access Denied</h1>
//                     <p className="text-gray-700">No user information available. Please log in.</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default TeacherHome;


import React, { useEffect, useState } from 'react';
import UserDetails from './UserDetails';
import { FaUserCircle } from "react-icons/fa";

const TeacherHome = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        try {
            const storedUser = JSON.parse(sessionStorage.getItem("user"));
            if (storedUser && storedUser.name) {
                setUser(storedUser);
            }
        } catch (error) {
            console.error("Error parsing user data:", error);
        }
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
            {user ? (
                <>
                    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl mb-8">
                        <div className="flex flex-col items-center mb-8">
                            <div className="bg-blue-600 text-white p-3 rounded-full mb-4">
                                <FaUserCircle className="text-7xl drop-shadow-md" />
                            </div>
                            <div className="bg-gradient-to-r  from-blue-500 to-purple-500 text-white px-8 py-3 rounded-lg shadow-md">
                                <h1 className="text-3xl font-bold">
                                    Welcome, {user.name}
                                </h1>
                                <p className="text-lg text-center mt-1">
                                    {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                                </p>
                            </div>
                        </div>
                        
                        <UserDetails user={user} />
                    </div>

                    {/* <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
                        <RecentActivity />
                    </div> */}
                </>
            ) : (
                <div className="text-center bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
                    <h1 className="text-4xl font-bold text-red-500 mb-4">Access Denied</h1>
                    <p className="text-gray-700">No user information available. Please log in.</p>
                </div>
            )}
        </div>
    );
};

export default TeacherHome;