// // import React, { useState,useNavigate, useParams, useLocation} from "react";

// // const courseQuestions = {
// //   101: [
// //     { id: 1, 
// //         question: "Q1. What is the primary use of CT brain plain study?", 
// //         options: ["To detect intracranial haemorrhage and fractures", "To diagnose brain tumors", "To detect sinus infections", "To monitor migraine headaches"] },
// //     { id: 2, 
// //         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
// //         options: ["Contrast enhances blood vessels, making fresh blood difficult to see", "Contrast is toxic for the brain", "Contrast is only used in MRI", "Contrast has no effect on CT scans"] },
// //     { id: 3, 
// //         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
// //         options: ["Hyperdense (bright)", "Hypodense (dark)", "Isodense", "Not visible"] },
// //     { id: 4, 
// //         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
// //         options: ["CT Brain Plain", "MRI DWI", "X-ray", "PET scan"] },
// //     { id: 5, 
// //         question: "What is a limitation of a CT brain plain study compared to MRI? ", 
// //         options: ["It is more expensive", "It cannot detect skull fractures", "It exposes the patient to ionizing radiation", "It takes longer to perform"] },
// //   ],
// //   102: [
// //     { id: 1, 
// //         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan??", 
// //         options: ["What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?"] },
// //     { id: 2, 
// //         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
// //         options: ["Glioblastoma Multiforme", "Tuberculoma", "Tuberculoma", "Tuberculoma"] },
// //     { id: 3, 
// //         question: "Q3. Which condition is most commonly associated with this finding?", 
// //         options: ["Subarachnoid Haemorrhage", "Hypertensive Intracerebral Haemorrhage", "Cavernous Malformation", "Ischemic Stroke"] },
// //     { id: 4, 
// //         question: "Q4. Which of the following conditions typically shows on a contrast-enhanced CT brain scan?", 
// //         options: ["Meningitis", "Multiple Sclerosis", "Diffuse Axonal Injury", "Alzheimer’s Disease"] },
// //     { id: 5, 
// //         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
// //         options: ["Suspected Brain Tumour", "History of Severe Contrast Allergy", "Evaluation of Brain Metastases", "Suspected Meningitis"] },
// //   ],
// //   103: [
// //     { id: 1, 
// //         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
// //         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Brain Tumor", "Normal Brain"] 
// //     },
// //     { id: 2, 
// //         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
// //         options: ["Normal Variation, MCA Thrombosis", "Subarachnoid Hemorrhage", "Meningioma"] },
// //     { id: 3, 
// //         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense (bright white) area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
// //         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Epidural Hematoma", "Epidural Hematoma"] },
// //     { id: 4, 
// //         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
// //         options: ["Observe and repeat CT in 24 hours", "Perform an MRI", "Neurosurgical evaluation for decompression", "Start Aspirin and Clopidogrel"] },
// //     { id: 5, 
// //         question: "A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis? ", 
// //         options: ["Epidural Hematoma", "Subdural Hematoma", "Ischemic Stroke", "Brain Abscess"] },
// //   ],
// // };

// // const Quiz = () => {
// //   // Use the courseId from URL parameters instead of hardcoding the course
// //   const { topicId, courseId } = useParams();
// //   // For quiz questions, we now use the courseId directly
// //   const selectedCourse = courseId;
  
// //   const [answers, setAnswers] = useState({});
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   // Retrieve registration_id passed from previous page
// //   const registrationId = location.state?.registration_id || "";

// //   const handleAnswerChange = (questionId, selectedOption) => {
// //     setAnswers((prev) => ({
// //       ...prev,
// //       [questionId]: selectedOption,
// //     }));
// //   };

  
// //   const handleSubmit = () => {
// //     // In a real application, you'd send the quiz responses along with the registration id to your backend.
// //     console.log(`User Answers for Course ${selectedCourse}:`, answers);
// //     console.log("Registration ID:", registrationId);
// //     alert(`Answers submitted for Course ${selectedCourse}! Registration ID: ${registrationId}`);
// //     // You can then call your API to save the quiz responses along with the registration id.
// //   };

// //   return (
// //     <div className="p-5">
// //       <h1 className="text-2xl font-bold mb-4">Course {selectedCourse} Quiz</h1>

// //       {courseQuestions[selectedCourse]?.length > 0 ? (
// //         <div>
// //           {courseQuestions[selectedCourse].map((q) => (
// //             <div key={q.id} className="mt-4 p-4 border rounded">
// //               <h2 className="text-lg font-semibold">{q.question}</h2>
// //               <ul className="list-none mt-2">
// //                 {q.options.map((option, index) => (
// //                   <li key={index} className="mt-1">
// //                     <input
// //                       type="radio"
// //                       name={`question-${q.id}`}
// //                       id={`q${q.id}-o${index}`}
// //                       value={option}
// //                       checked={answers[q.id] === option}
// //                       onChange={() => handleAnswerChange(q.id, option)}
// //                     />
// //                     <label htmlFor={`q${q.id}-o${index}`} className="ml-2">
// //                       {option}
// //                     </label>
// //                   </li>
// //                 ))}
// //               </ul>
// //             </div>
// //           ))}
// //           <button
// //             onClick={handleSubmit}
// //             className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
// //           >
// //             Complete The Course
// //           </button>
// //         </div>
// //       ) : (
// //         <p className="mt-4 text-gray-600">No questions available for Course {selectedCourse}.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default Quiz;

// // import React, { useState } from "react";
// // import { useNavigate, useParams, useLocation } from "react-router-dom";

// // const courseQuestions = {
// //   101: [
// //     { id: 1, 
// //         question: "Q1. What is the primary use of CT brain plain study?", 
// //         options: ["To detect intracranial haemorrhage and fractures", "To diagnose brain tumors", "To detect sinus infections", "To monitor migraine headaches"] },
// //     { id: 2, 
// //         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
// //         options: ["Contrast enhances blood vessels, making fresh blood difficult to see", "Contrast is toxic for the brain", "Contrast is only used in MRI", "Contrast has no effect on CT scans"] },
// //     { id: 3, 
// //         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
// //         options: ["Hyperdense (bright)", "Hypodense (dark)", "Isodense", "Not visible"] },
// //     { id: 4, 
// //         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
// //         options: ["CT Brain Plain", "MRI DWI", "X-ray", "PET scan"] },
// //     { id: 5, 
// //         question: "What is a limitation of a CT brain plain study compared to MRI? ", 
// //         options: ["It is more expensive", "It cannot detect skull fractures", "It exposes the patient to ionizing radiation", "It takes longer to perform"] },
// //   ],
// //   102: [
// //     { id: 1, 
// //         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan??", 
// //         options: ["What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?"] },
// //     { id: 2, 
// //         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
// //         options: ["Glioblastoma Multiforme", "Tuberculoma", "Tuberculoma", "Tuberculoma"] },
// //     { id: 3, 
// //         question: "Q3. Which condition is most commonly associated with this finding?", 
// //         options: ["Subarachnoid Haemorrhage", "Hypertensive Intracerebral Haemorrhage", "Cavernous Malformation", "Ischemic Stroke"] },
// //     { id: 4, 
// //         question: "Q4. Which of the following conditions typically shows on a contrast-enhanced CT brain scan?", 
// //         options: ["Meningitis", "Multiple Sclerosis", "Diffuse Axonal Injury", "Alzheimer’s Disease"] },
// //     { id: 5, 
// //         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
// //         options: ["Suspected Brain Tumour", "History of Severe Contrast Allergy", "Evaluation of Brain Metastases", "Suspected Meningitis"] },
// //   ],
// //   103: [
// //     { id: 1, 
// //         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
// //         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Brain Tumor", "Normal Brain"] 
// //     },
// //     { id: 2, 
// //         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
// //         options: ["Normal Variation, MCA Thrombosis", "Subarachnoid Haemorrhage", "Meningioma"] },
// //     { id: 3, 
// //         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense (bright white) area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
// //         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Epidural Hematoma", "Epidural Hematoma"] },
// //     { id: 4, 
// //         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
// //         options: ["Observe and repeat CT in 24 hours", "Perform an MRI", "Neurosurgical evaluation for decompression", "Start Aspirin and Clopidogrel"] },
// //     { id: 5, 
// //         question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis? ", 
// //         options: ["Epidural Hematoma", "Subdural Hematoma", "Ischemic Stroke", "Brain Abscess"] },
// //   ],
// // };

// // const Quiz = () => {
// //   const { topicId, courseId } = useParams();
// //   const selectedCourse = courseId;
  
// //   const [answers, setAnswers] = useState({});
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const registrationId = location.state?.registration_id || "";

// //   const handleAnswerChange = (questionId, selectedOption) => {
// //     setAnswers((prev) => ({
// //       ...prev,
// //       [questionId]: selectedOption,
// //     }));
// //   };

// //   const handleSubmit = () => {
// //     console.log(`User Answers for Course ${selectedCourse}:`, answers);
// //     console.log("Registration ID:", registrationId);
// //     alert(`Answers submitted for Course ${selectedCourse}! Registration ID: ${registrationId}`);
// //     navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
// //       state: { registration_id: registrationId, answers }
// //     });
// //   };

// //   return (
// //     <div className="p-5">
// //       <h1 className="text-2xl font-bold mb-4">Course {selectedCourse} Quiz</h1>

// //       {courseQuestions[selectedCourse]?.length > 0 ? (
// //         <div>
// //           {courseQuestions[selectedCourse].map((q) => (
// //             <div key={q.id} className="mt-4 p-4 border rounded">
// //               <h2 className="text-lg font-semibold">{q.question}</h2>
// //               <ul className="list-none mt-2">
// //                 {q.options.map((option, index) => (
// //                   <li key={index} className="mt-1">
// //                     <input
// //                       type="radio"
// //                       name={`question-${q.id}`}
// //                       id={`q${q.id}-o${index}`}
// //                       value={option}
// //                       checked={answers[q.id] === option}
// //                       onChange={() => handleAnswerChange(q.id, option)}
// //                     />
// //                     <label htmlFor={`q${q.id}-o${index}`} className="ml-2">
// //                       {option}
// //                     </label>
// //                   </li>
// //                 ))}
// //               </ul>
// //             </div>
// //           ))}
// //           <button
// //             onClick={handleSubmit}
// //             className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
// //           >
// //             Complete The Course
// //           </button>
// //         </div>
// //       ) : (
// //         <p className="mt-4 text-gray-600">No questions available for Course {selectedCourse}.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default Quiz;








// // import React, { useState } from "react";
// // import { useNavigate, useParams, useLocation } from "react-router-dom";

// // // Quiz data for different courses
// // const courseQuestions = {
// //   "101": {
// //     title: "CT Brain Plain Study Quiz",
// //     description: "Test your knowledge about CT brain plain study fundamentals",
// //     questions: [
// //       { 
// //         id: 1, 
// //         question: "Q1. What is the primary use of CT brain plain study?", 
// //         options: [
// //           "To detect intracranial haemorrhage and fractures",
// //           "To diagnose brain tumors",
// //           "To detect sinus infections",
// //           "To monitor migraine headaches"
// //         ]
// //       },
// //       { 
// //         id: 2, 
// //         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
// //         options: [
// //           "Contrast enhances blood vessels, making fresh blood difficult to see",
// //           "Contrast is toxic for the brain",
// //           "Contrast is only used in MRI",
// //           "Contrast has no effect on CT scans"
// //         ]
// //       },
// //       { 
// //         id: 3, 
// //         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
// //         options: [
// //           "Hyperdense (bright)",
// //           "Hypodense (dark)",
// //           "Isodense",
// //           "Not visible"
// //         ]
// //       },
// //       { 
// //         id: 4, 
// //         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
// //         options: [
// //           "CT Brain Plain",
// //           "MRI DWI",
// //           "X-ray",
// //           "PET scan"
// //         ]
// //       },
// //       { 
// //         id: 5, 
// //         question: "Q5. What is a limitation of a CT brain plain study compared to MRI?", 
// //         options: [
// //           "It is more expensive",
// //           "It cannot detect skull fractures",
// //           "It exposes the patient to ionizing radiation",
// //           "It takes longer to perform"
// //         ]
// //       }
// //     ]
// //   },
// //   "102": {
// //     title: "Contrast-Enhanced CT Brain Quiz",
// //     description: "Evaluate your understanding of contrast-enhanced CT brain scans",
// //     questions: [
// //       { 
// //         id: 1, 
// //         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan?", 
// //         options: [
// //           "To evaluate brain tumors and metastases",
// //           "To detect acute hemorrhage",
// //           "To diagnose skull fractures",
// //           "To assess brain atrophy"
// //         ]
// //       },
// //       { 
// //         id: 2, 
// //         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
// //         options: [
// //           "Glioblastoma Multiforme",
// //           "Tuberculoma",
// //           "Meningioma",
// //           "Brain Abscess"
// //         ]
// //       },
// //       { 
// //         id: 3, 
// //         question: "Q3. Which condition is most commonly associated with this finding?", 
// //         options: [
// //           "Subarachnoid Haemorrhage",
// //           "Hypertensive Intracerebral Haemorrhage",
// //           "Cavernous Malformation",
// //           "Ischemic Stroke"
// //         ]
// //       },
// //       { 
// //         id: 4, 
// //         question: "Q4. Which of the following conditions typically shows enhancement on a contrast-enhanced CT brain scan?", 
// //         options: [
// //           "Meningitis",
// //           "Multiple Sclerosis",
// //           "Diffuse Axonal Injury",
// //           "Alzheimer's Disease"
// //         ]
// //       },
// //       { 
// //         id: 5, 
// //         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
// //         options: [
// //           "Suspected Brain Tumour",
// //           "History of Severe Contrast Allergy",
// //           "Evaluation of Brain Metastases",
// //           "Suspected Meningitis"
// //         ]
// //       }
// //     ]
// //   },
// //   "103": {
// //     title: "Clinical Case-Based CT Brain Quiz",
// //     description: "Test your diagnostic skills with real clinical scenarios",
// //     questions: [
// //       { 
// //         id: 1, 
// //         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
// //         options: [
// //           "Ischemic Stroke",
// //           "Haemorrhagic Stroke",
// //           "Brain Tumor",
// //           "Normal Brain"
// //         ]
// //       },
// //       { 
// //         id: 2, 
// //         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
// //         options: [
// //           "MCA Thrombosis",
// //           "Normal Variation",
// //           "Subarachnoid Hemorrhage",
// //           "Meningioma"
// //         ]
// //       },
// //       { 
// //         id: 3, 
// //         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
// //         options: [
// //           "Ischemic Stroke",
// //           "Haemorrhagic Stroke",
// //           "Epidural Hematoma",
// //           "Brain Abscess"
// //         ]
// //       },
// //       { 
// //         id: 4, 
// //         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
// //         options: [
// //           "Observe and repeat CT in 24 hours",
// //           "Perform an MRI",
// //           "Neurosurgical evaluation for decompression",
// //           "Start Aspirin and Clopidogrel"
// //         ]
// //       },
// //       { 
// //         id: 5, 
// //         question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis?", 
// //         options: [
// //           "Epidural Hematoma",
// //           "Subdural Hematoma",
// //           "Ischemic Stroke",
// //           "Brain Abscess"
// //         ]
// //       }
// //     ]
// //   }
// // };

// // const Quiz = () => {
// //   const { courseId } = useParams();
// //   // const navigate = useNavigate();
// //   const [answers, setAnswers] = useState({});
// //   const [submitted, setSubmitted] = useState(false);
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const registrationId = location.state?.registration_id || "";

// //   // Get current course data
// //   const currentCourse = courseQuestions[courseId] || {
// //     title: "Quiz Not Available",
// //     description: "No quiz available for this course.",
// //     questions: []
// //   };

// //   const handleAnswerChange = (questionId, selectedOption) => {
// //     setAnswers(prev => ({
// //       ...prev,
// //       [questionId]: selectedOption
// //     }));
// //   };

// //   const handleSubmit = () => {
// //     // Check if all questions are answered
// //     const totalQuestions = currentCourse.questions.length;
// //     const answeredQuestions = Object.keys(answers).length;

// //     if (answeredQuestions < totalQuestions) {
// //       alert(`Please answer all questions before submitting. (${answeredQuestions}/${totalQuestions} answered)`);
// //       return;
// //     }

// //     setSubmitted(true);
// //     console.log(`Quiz Submitted for Course ${courseId}:`, answers);
// //     // Here you can add API call to submit answers
// //     setTimeout(() => {
// //       alert("Quiz completed successfully!");
// //       navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
// //         state: { registration_id: registrationId }
// //       });
// //   };

// //   return (
// //     <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen p-6">
// //       <div className="max-w-4xl mx-auto">
// //         {/* Header */}
// //         <div className="flex items-center mb-8">
// //           <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center mr-4">
// //             <span className="text-xl text-white">📝</span>
// //           </div>
// //           <div>
// //             <h1 className="text-3xl font-bold text-gray-800">{currentCourse.title}</h1>
// //             <p className="text-gray-600 mt-1">{currentCourse.description}</p>
// //           </div>
// //         </div>

// //         {/* Quiz Content */}
// //         {currentCourse.questions.length > 0 ? (
// //           <div className="space-y-6">
// //             {currentCourse.questions.map((q) => (
// //               <div key={q.id} className="bg-white rounded-xl shadow-md overflow-hidden">
// //                 <div className="p-6">
// //                   <h2 className="text-lg font-semibold text-gray-800 mb-4">{q.question}</h2>
// //                   <div className="space-y-3">
// //                     {q.options.map((option, index) => (
// //                       <label 
// //                         key={index}
// //                         className={`flex items-center p-3 rounded-lg border transition-all cursor-pointer
// //                           ${answers[q.id] === option 
// //                             ? 'bg-blue-50 border-blue-500' 
// //                             : 'border-gray-200 hover:bg-gray-50'}`}
// //                       >
// //                         <input
// //                           type="radio"
// //                           name={`question-${q.id}`}
// //                           value={option}
// //                           checked={answers[q.id] === option}
// //                           onChange={() => handleAnswerChange(q.id, option)}
// //                           className="w-4 h-4 text-blue-600"
// //                         />
// //                         <span className="ml-3 text-gray-700">{option}</span>
// //                       </label>
// //                     ))}
// //                   </div>
// //                 </div>
// //               </div>
// //             ))}

// //             {/* Submit Button */}
// //             <div className="flex justify-end mt-8">
// //               <button
// //                 onClick={handleSubmit}
// //                 disabled={submitted}
// //                 className={`px-6 py-3 rounded-lg font-medium flex items-center gap-2 transition-all
// //                   ${submitted 
// //                     ? 'bg-gray-400 cursor-not-allowed' 
// //                     : 'bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg'}`}
// //               >
// //                 {submitted ? (
// //                   <>
// //                     <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
// //                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
// //                       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
// //                     </svg>
// //                     Submitting...
// //                   </>
// //                 ) : (
// //                   <>
// //                     Complete The Course
// //                     <span className="text-xl">✓</span>
// //                   </>
// //                 )}
// //               </button>
// //             </div>
// //           </div>
// //         ) : (
// //           <div className="bg-white rounded-xl shadow-md p-6 text-center">
// //             <p className="text-gray-600 text-lg">No questions available for this course.</p>
// //           </div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Quiz;


// import React, { useState } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";

// // Quiz data for different courses
// const courseQuestions = {
//   "101": {
//     title: "CT Brain Plain Study Quiz",
//     description: "Test your knowledge about CT brain plain study fundamentals",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. What is the primary use of CT brain plain study?", 
//         options: [
//           "To detect intracranial haemorrhage and fractures",
//           "To diagnose brain tumors",
//           "To detect sinus infections",
//           "To monitor migraine headaches"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
//         options: [
//           "Contrast enhances blood vessels, making fresh blood difficult to see",
//           "Contrast is toxic for the brain",
//           "Contrast is only used in MRI",
//           "Contrast has no effect on CT scans"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
//         options: [
//           "Hyperdense (bright)",
//           "Hypodense (dark)",
//           "Isodense",
//           "Not visible"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
//         options: [
//           "CT Brain Plain",
//           "MRI DWI",
//           "X-ray",
//           "PET scan"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. What is a limitation of a CT brain plain study compared to MRI?", 
//         options: [
//           "It is more expensive",
//           "It cannot detect skull fractures",
//           "It exposes the patient to ionizing radiation",
//           "It takes longer to perform"
//         ]
//       }
//     ]
//   },
//   "102": {
//     title: "Contrast-Enhanced CT Brain Quiz",
//     description: "Evaluate your understanding of contrast-enhanced CT brain scans",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan?", 
//         options: [
//           "To evaluate brain tumors and metastases",
//           "To detect acute hemorrhage",
//           "To diagnose skull fractures",
//           "To assess brain atrophy"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
//         options: [
//           "Glioblastoma Multiforme",
//           "Tuberculoma",
//           "Meningioma",
//           "Brain Abscess"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. Which condition is most commonly associated with this finding?", 
//         options: [
//           "Subarachnoid Haemorrhage",
//           "Hypertensive Intracerebral Haemorrhage",
//           "Cavernous Malformation",
//           "Ischemic Stroke"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. Which of the following conditions typically shows enhancement on a contrast-enhanced CT brain scan?", 
//         options: [
//           "Meningitis",
//           "Multiple Sclerosis",
//           "Diffuse Axonal Injury",
//           "Alzheimer's Disease"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
//         options: [
//           "Suspected Brain Tumour",
//           "History of Severe Contrast Allergy",
//           "Evaluation of Brain Metastases",
//           "Suspected Meningitis"
//         ]
//       }
//     ]
//   },
//   "103": {
//     title: "Clinical Case-Based CT Brain Quiz",
//     description: "Test your diagnostic skills with real clinical scenarios",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
//         options: [
//           "Ischemic Stroke",
//           "Haemorrhagic Stroke",
//           "Brain Tumor",
//           "Normal Brain"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
//         options: [
//           "MCA Thrombosis",
//           "Normal Variation",
//           "Subarachnoid Haemorrhage",
//           "Meningioma"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
//         options: [
//           "Ischemic Stroke",
//           "Haemorrhagic Stroke",
//           "Epidural Hematoma",
//           "Brain Abscess"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
//         options: [
//           "Observe and repeat CT in 24 hours",
//           "Perform an MRI",
//           "Neurosurgical evaluation for decompression",
//           "Start Aspirin and Clopidogrel"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis?", 
//         options: [
//           "Epidural Hematoma",
//           "Subdural Hematoma",
//           "Ischemic Stroke",
//           "Brain Abscess"
//         ]
//       }
//     ]
//   }
// };

// const Quiz = () => {
//   const { courseId } = useParams();
//   const [answers, setAnswers] = useState({});
//   const [submitted, setSubmitted] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const registrationId = location.state?.registration_id || "";

//   // Get current course data
//   const currentCourse = courseQuestions[courseId] || {
//     title: "Quiz Not Available",
//     description: "No quiz available for this course.",
//     questions: []
//   };

//   const handleAnswerChange = (questionId, selectedOption) => {
//     setAnswers(prev => ({
//       ...prev,
//       [questionId]: selectedOption
//     }));
//   };

//   const handleSubmit = () => {
//     // Check if all questions are answered
//     const totalQuestions = currentCourse.questions.length;
//     const answeredQuestions = Object.keys(answers).length;

//     if (answeredQuestions < totalQuestions) {
//       alert(`Please answer all questions before submitting. (${answeredQuestions}/${totalQuestions} answered)`);
//       return;
//     }

//     setSubmitted(true);
//     console.log(`Quiz Submitted for Course ${courseId}:`, answers);
//     // Here you can add an API call to submit answers

//     // Define topicId if it's needed (replace "default-topic" with your actual topicId)
//     const topicId = "default-topic";

//     setTimeout(() => {
//       alert("Quiz completed successfully!");
//       navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
//         state: { registration_id: registrationId }
//       });
//     }, 1000);
//   };

//   return (
//     <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen p-6">
//       <div className="max-w-4xl mx-auto">
//         {/* Header */}
//         <div className="flex items-center mb-8">
//           <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center mr-4">
//             <span className="text-xl text-white">📝</span>
//           </div>
//           <div>
//             <h1 className="text-3xl font-bold text-gray-800">{currentCourse.title}</h1>
//             <p className="text-gray-600 mt-1">{currentCourse.description}</p>
//           </div>
//         </div>

//         {/* Quiz Content */}
//         {currentCourse.questions.length > 0 ? (
//           <div className="space-y-6">
//             {currentCourse.questions.map((q) => (
//               <div key={q.id} className="bg-white rounded-xl shadow-md overflow-hidden">
//                 <div className="p-6">
//                   <h2 className="text-lg font-semibold text-gray-800 mb-4">{q.question}</h2>
//                   <div className="space-y-3">
//                     {q.options.map((option, index) => (
//                       <label 
//                         key={index}
//                         className={`flex items-center p-3 rounded-lg border transition-all cursor-pointer
//                           ${answers[q.id] === option 
//                             ? 'bg-blue-50 border-blue-500' 
//                             : 'border-gray-200 hover:bg-gray-50'}`}
//                       >
//                         <input
//                           type="radio"
//                           name={`question-${q.id}`}
//                           value={option}
//                           checked={answers[q.id] === option}
//                           onChange={() => handleAnswerChange(q.id, option)}
//                           className="w-4 h-4 text-blue-600"
//                         />
//                         <span className="ml-3 text-gray-700">{option}</span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             ))}

//             {/* Submit Button */}
//             <div className="flex justify-end mt-8">
//               <button
//                 onClick={handleSubmit}
//                 disabled={submitted}
//                 className={`px-6 py-3 rounded-lg font-medium flex items-center gap-2 transition-all
//                   ${submitted 
//                     ? 'bg-gray-400 cursor-not-allowed' 
//                     : 'bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg'}`}
//               >
//                 {submitted ? (
//                   <>
//                     <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                     </svg>
//                     Submitting...
//                   </>
//                 ) : (
//                   <>
//                     Complete The Course
//                     <span className="text-xl">✓</span>
//                   </>
//                 )}
//               </button>
//             </div>
//           </div>
//         ) : (
//           <div className="bg-white rounded-xl shadow-md p-6 text-center">
//             <p className="text-gray-600 text-lg">No questions available for this course.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Quiz;
// import React, { useState,useNavigate, useParams, useLocation} from "react";

// const courseQuestions = {
//   101: [
//     { id: 1, 
//         question: "Q1. What is the primary use of CT brain plain study?", 
//         options: ["To detect intracranial haemorrhage and fractures", "To diagnose brain tumors", "To detect sinus infections", "To monitor migraine headaches"] },
//     { id: 2, 
//         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
//         options: ["Contrast enhances blood vessels, making fresh blood difficult to see", "Contrast is toxic for the brain", "Contrast is only used in MRI", "Contrast has no effect on CT scans"] },
//     { id: 3, 
//         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
//         options: ["Hyperdense (bright)", "Hypodense (dark)", "Isodense", "Not visible"] },
//     { id: 4, 
//         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
//         options: ["CT Brain Plain", "MRI DWI", "X-ray", "PET scan"] },
//     { id: 5, 
//         question: "What is a limitation of a CT brain plain study compared to MRI? ", 
//         options: ["It is more expensive", "It cannot detect skull fractures", "It exposes the patient to ionizing radiation", "It takes longer to perform"] },
//   ],
//   102: [
//     { id: 1, 
//         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan??", 
//         options: ["What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?"] },
//     { id: 2, 
//         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
//         options: ["Glioblastoma Multiforme", "Tuberculoma", "Tuberculoma", "Tuberculoma"] },
//     { id: 3, 
//         question: "Q3. Which condition is most commonly associated with this finding?", 
//         options: ["Subarachnoid Haemorrhage", "Hypertensive Intracerebral Haemorrhage", "Cavernous Malformation", "Ischemic Stroke"] },
//     { id: 4, 
//         question: "Q4. Which of the following conditions typically shows on a contrast-enhanced CT brain scan?", 
//         options: ["Meningitis", "Multiple Sclerosis", "Diffuse Axonal Injury", "Alzheimer’s Disease"] },
//     { id: 5, 
//         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
//         options: ["Suspected Brain Tumour", "History of Severe Contrast Allergy", "Evaluation of Brain Metastases", "Suspected Meningitis"] },
//   ],
//   103: [
//     { id: 1, 
//         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
//         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Brain Tumor", "Normal Brain"] 
//     },
//     { id: 2, 
//         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
//         options: ["Normal Variation, MCA Thrombosis", "Subarachnoid Hemorrhage", "Meningioma"] },
//     { id: 3, 
//         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense (bright white) area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
//         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Epidural Hematoma", "Epidural Hematoma"] },
//     { id: 4, 
//         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
//         options: ["Observe and repeat CT in 24 hours", "Perform an MRI", "Neurosurgical evaluation for decompression", "Start Aspirin and Clopidogrel"] },
//     { id: 5, 
//         question: "A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis? ", 
//         options: ["Epidural Hematoma", "Subdural Hematoma", "Ischemic Stroke", "Brain Abscess"] },
//   ],
// };

// const Quiz = () => {
//   // Use the courseId from URL parameters instead of hardcoding the course
//   const { topicId, courseId } = useParams();
//   // For quiz questions, we now use the courseId directly
//   const selectedCourse = courseId;
  
//   const [answers, setAnswers] = useState({});
//   const navigate = useNavigate();
//   const location = useLocation();
//   // Retrieve registration_id passed from previous page
//   const registrationId = location.state?.registration_id || "";

//   const handleAnswerChange = (questionId, selectedOption) => {
//     setAnswers((prev) => ({
//       ...prev,
//       [questionId]: selectedOption,
//     }));
//   };

  
//   const handleSubmit = () => {
//     // In a real application, you'd send the quiz responses along with the registration id to your backend.
//     console.log(`User Answers for Course ${selectedCourse}:`, answers);
//     console.log("Registration ID:", registrationId);
//     alert(`Answers submitted for Course ${selectedCourse}! Registration ID: ${registrationId}`);
//     // You can then call your API to save the quiz responses along with the registration id.
//   };

//   return (
//     <div className="p-5">
//       <h1 className="text-2xl font-bold mb-4">Course {selectedCourse} Quiz</h1>

//       {courseQuestions[selectedCourse]?.length > 0 ? (
//         <div>
//           {courseQuestions[selectedCourse].map((q) => (
//             <div key={q.id} className="mt-4 p-4 border rounded">
//               <h2 className="text-lg font-semibold">{q.question}</h2>
//               <ul className="list-none mt-2">
//                 {q.options.map((option, index) => (
//                   <li key={index} className="mt-1">
//                     <input
//                       type="radio"
//                       name={`question-${q.id}`}
//                       id={`q${q.id}-o${index}`}
//                       value={option}
//                       checked={answers[q.id] === option}
//                       onChange={() => handleAnswerChange(q.id, option)}
//                     />
//                     <label htmlFor={`q${q.id}-o${index}`} className="ml-2">
//                       {option}
//                     </label>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//           <button
//             onClick={handleSubmit}
//             className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
//           >
//             Complete The Course
//           </button>
//         </div>
//       ) : (
//         <p className="mt-4 text-gray-600">No questions available for Course {selectedCourse}.</p>
//       )}
//     </div>
//   );
// };

// export default Quiz;

// import React, { useState } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";

// const courseQuestions = {
//   101: [
//     { id: 1, 
//         question: "Q1. What is the primary use of CT brain plain study?", 
//         options: ["To detect intracranial haemorrhage and fractures", "To diagnose brain tumors", "To detect sinus infections", "To monitor migraine headaches"] },
//     { id: 2, 
//         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
//         options: ["Contrast enhances blood vessels, making fresh blood difficult to see", "Contrast is toxic for the brain", "Contrast is only used in MRI", "Contrast has no effect on CT scans"] },
//     { id: 3, 
//         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
//         options: ["Hyperdense (bright)", "Hypodense (dark)", "Isodense", "Not visible"] },
//     { id: 4, 
//         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
//         options: ["CT Brain Plain", "MRI DWI", "X-ray", "PET scan"] },
//     { id: 5, 
//         question: "What is a limitation of a CT brain plain study compared to MRI? ", 
//         options: ["It is more expensive", "It cannot detect skull fractures", "It exposes the patient to ionizing radiation", "It takes longer to perform"] },
//   ],
//   102: [
//     { id: 1, 
//         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan??", 
//         options: ["What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?", "What is the primary indication for performing a contrast-enhanced CT brain scan?"] },
//     { id: 2, 
//         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
//         options: ["Glioblastoma Multiforme", "Tuberculoma", "Tuberculoma", "Tuberculoma"] },
//     { id: 3, 
//         question: "Q3. Which condition is most commonly associated with this finding?", 
//         options: ["Subarachnoid Haemorrhage", "Hypertensive Intracerebral Haemorrhage", "Cavernous Malformation", "Ischemic Stroke"] },
//     { id: 4, 
//         question: "Q4. Which of the following conditions typically shows on a contrast-enhanced CT brain scan?", 
//         options: ["Meningitis", "Multiple Sclerosis", "Diffuse Axonal Injury", "Alzheimer’s Disease"] },
//     { id: 5, 
//         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
//         options: ["Suspected Brain Tumour", "History of Severe Contrast Allergy", "Evaluation of Brain Metastases", "Suspected Meningitis"] },
//   ],
//   103: [
//     { id: 1, 
//         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
//         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Brain Tumor", "Normal Brain"] 
//     },
//     { id: 2, 
//         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
//         options: ["Normal Variation, MCA Thrombosis", "Subarachnoid Haemorrhage", "Meningioma"] },
//     { id: 3, 
//         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense (bright white) area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
//         options: ["Ischemic Stroke", "Haemorrhagic Stroke", "Epidural Hematoma", "Epidural Hematoma"] },
//     { id: 4, 
//         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
//         options: ["Observe and repeat CT in 24 hours", "Perform an MRI", "Neurosurgical evaluation for decompression", "Start Aspirin and Clopidogrel"] },
//     { id: 5, 
//         question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis? ", 
//         options: ["Epidural Hematoma", "Subdural Hematoma", "Ischemic Stroke", "Brain Abscess"] },
//   ],
// };

// const Quiz = () => {
//   const { topicId, courseId } = useParams();
//   const selectedCourse = courseId;
  
//   const [answers, setAnswers] = useState({});
//   const navigate = useNavigate();
//   const location = useLocation();
//   const registrationId = location.state?.registration_id || "";

//   const handleAnswerChange = (questionId, selectedOption) => {
//     setAnswers((prev) => ({
//       ...prev,
//       [questionId]: selectedOption,
//     }));
//   };

//   const handleSubmit = () => {
//     console.log(`User Answers for Course ${selectedCourse}:`, answers);
//     console.log("Registration ID:", registrationId);
//     alert(`Answers submitted for Course ${selectedCourse}! Registration ID: ${registrationId}`);
//     navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
//       state: { registration_id: registrationId, answers }
//     });
//   };

//   return (
//     <div className="p-5">
//       <h1 className="text-2xl font-bold mb-4">Course {selectedCourse} Quiz</h1>

//       {courseQuestions[selectedCourse]?.length > 0 ? (
//         <div>
//           {courseQuestions[selectedCourse].map((q) => (
//             <div key={q.id} className="mt-4 p-4 border rounded">
//               <h2 className="text-lg font-semibold">{q.question}</h2>
//               <ul className="list-none mt-2">
//                 {q.options.map((option, index) => (
//                   <li key={index} className="mt-1">
//                     <input
//                       type="radio"
//                       name={`question-${q.id}`}
//                       id={`q${q.id}-o${index}`}
//                       value={option}
//                       checked={answers[q.id] === option}
//                       onChange={() => handleAnswerChange(q.id, option)}
//                     />
//                     <label htmlFor={`q${q.id}-o${index}`} className="ml-2">
//                       {option}
//                     </label>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//           <button
//             onClick={handleSubmit}
//             className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
//           >
//             Complete The Course
//           </button>
//         </div>
//       ) : (
//         <p className="mt-4 text-gray-600">No questions available for Course {selectedCourse}.</p>
//       )}
//     </div>
//   );
// };

// export default Quiz;








// import React, { useState } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";

// // Quiz data for different courses
// const courseQuestions = {
//   "101": {
//     title: "CT Brain Plain Study Quiz",
//     description: "Test your knowledge about CT brain plain study fundamentals",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. What is the primary use of CT brain plain study?", 
//         options: [
//           "To detect intracranial haemorrhage and fractures",
//           "To diagnose brain tumors",
//           "To detect sinus infections",
//           "To monitor migraine headaches"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
//         options: [
//           "Contrast enhances blood vessels, making fresh blood difficult to see",
//           "Contrast is toxic for the brain",
//           "Contrast is only used in MRI",
//           "Contrast has no effect on CT scans"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
//         options: [
//           "Hyperdense (bright)",
//           "Hypodense (dark)",
//           "Isodense",
//           "Not visible"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
//         options: [
//           "CT Brain Plain",
//           "MRI DWI",
//           "X-ray",
//           "PET scan"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. What is a limitation of a CT brain plain study compared to MRI?", 
//         options: [
//           "It is more expensive",
//           "It cannot detect skull fractures",
//           "It exposes the patient to ionizing radiation",
//           "It takes longer to perform"
//         ]
//       }
//     ]
//   },
//   "102": {
//     title: "Contrast-Enhanced CT Brain Quiz",
//     description: "Evaluate your understanding of contrast-enhanced CT brain scans",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan?", 
//         options: [
//           "To evaluate brain tumors and metastases",
//           "To detect acute hemorrhage",
//           "To diagnose skull fractures",
//           "To assess brain atrophy"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
//         options: [
//           "Glioblastoma Multiforme",
//           "Tuberculoma",
//           "Meningioma",
//           "Brain Abscess"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. Which condition is most commonly associated with this finding?", 
//         options: [
//           "Subarachnoid Haemorrhage",
//           "Hypertensive Intracerebral Haemorrhage",
//           "Cavernous Malformation",
//           "Ischemic Stroke"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. Which of the following conditions typically shows enhancement on a contrast-enhanced CT brain scan?", 
//         options: [
//           "Meningitis",
//           "Multiple Sclerosis",
//           "Diffuse Axonal Injury",
//           "Alzheimer's Disease"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
//         options: [
//           "Suspected Brain Tumour",
//           "History of Severe Contrast Allergy",
//           "Evaluation of Brain Metastases",
//           "Suspected Meningitis"
//         ]
//       }
//     ]
//   },
//   "103": {
//     title: "Clinical Case-Based CT Brain Quiz",
//     description: "Test your diagnostic skills with real clinical scenarios",
//     questions: [
//       { 
//         id: 1, 
//         question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
//         options: [
//           "Ischemic Stroke",
//           "Haemorrhagic Stroke",
//           "Brain Tumor",
//           "Normal Brain"
//         ]
//       },
//       { 
//         id: 2, 
//         question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
//         options: [
//           "MCA Thrombosis",
//           "Normal Variation",
//           "Subarachnoid Hemorrhage",
//           "Meningioma"
//         ]
//       },
//       { 
//         id: 3, 
//         question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
//         options: [
//           "Ischemic Stroke",
//           "Haemorrhagic Stroke",
//           "Epidural Hematoma",
//           "Brain Abscess"
//         ]
//       },
//       { 
//         id: 4, 
//         question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
//         options: [
//           "Observe and repeat CT in 24 hours",
//           "Perform an MRI",
//           "Neurosurgical evaluation for decompression",
//           "Start Aspirin and Clopidogrel"
//         ]
//       },
//       { 
//         id: 5, 
//         question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis?", 
//         options: [
//           "Epidural Hematoma",
//           "Subdural Hematoma",
//           "Ischemic Stroke",
//           "Brain Abscess"
//         ]
//       }
//     ]
//   }
// };

// const Quiz = () => {
//   const { courseId } = useParams();
//   // const navigate = useNavigate();
//   const [answers, setAnswers] = useState({});
//   const [submitted, setSubmitted] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const registrationId = location.state?.registration_id || "";

//   // Get current course data
//   const currentCourse = courseQuestions[courseId] || {
//     title: "Quiz Not Available",
//     description: "No quiz available for this course.",
//     questions: []
//   };

//   const handleAnswerChange = (questionId, selectedOption) => {
//     setAnswers(prev => ({
//       ...prev,
//       [questionId]: selectedOption
//     }));
//   };

//   const handleSubmit = () => {
//     // Check if all questions are answered
//     const totalQuestions = currentCourse.questions.length;
//     const answeredQuestions = Object.keys(answers).length;

//     if (answeredQuestions < totalQuestions) {
//       alert(`Please answer all questions before submitting. (${answeredQuestions}/${totalQuestions} answered)`);
//       return;
//     }

//     setSubmitted(true);
//     console.log(`Quiz Submitted for Course ${courseId}:`, answers);
//     // Here you can add API call to submit answers
//     setTimeout(() => {
//       alert("Quiz completed successfully!");
//       navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
//         state: { registration_id: registrationId }
//       });
//   };

//   return (
//     <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen p-6">
//       <div className="max-w-4xl mx-auto">
//         {/* Header */}
//         <div className="flex items-center mb-8">
//           <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center mr-4">
//             <span className="text-xl text-white">📝</span>
//           </div>
//           <div>
//             <h1 className="text-3xl font-bold text-gray-800">{currentCourse.title}</h1>
//             <p className="text-gray-600 mt-1">{currentCourse.description}</p>
//           </div>
//         </div>

//         {/* Quiz Content */}
//         {currentCourse.questions.length > 0 ? (
//           <div className="space-y-6">
//             {currentCourse.questions.map((q) => (
//               <div key={q.id} className="bg-white rounded-xl shadow-md overflow-hidden">
//                 <div className="p-6">
//                   <h2 className="text-lg font-semibold text-gray-800 mb-4">{q.question}</h2>
//                   <div className="space-y-3">
//                     {q.options.map((option, index) => (
//                       <label 
//                         key={index}
//                         className={`flex items-center p-3 rounded-lg border transition-all cursor-pointer
//                           ${answers[q.id] === option 
//                             ? 'bg-blue-50 border-blue-500' 
//                             : 'border-gray-200 hover:bg-gray-50'}`}
//                       >
//                         <input
//                           type="radio"
//                           name={`question-${q.id}`}
//                           value={option}
//                           checked={answers[q.id] === option}
//                           onChange={() => handleAnswerChange(q.id, option)}
//                           className="w-4 h-4 text-blue-600"
//                         />
//                         <span className="ml-3 text-gray-700">{option}</span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             ))}

//             {/* Submit Button */}
//             <div className="flex justify-end mt-8">
//               <button
//                 onClick={handleSubmit}
//                 disabled={submitted}
//                 className={`px-6 py-3 rounded-lg font-medium flex items-center gap-2 transition-all
//                   ${submitted 
//                     ? 'bg-gray-400 cursor-not-allowed' 
//                     : 'bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg'}`}
//               >
//                 {submitted ? (
//                   <>
//                     <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                     </svg>
//                     Submitting...
//                   </>
//                 ) : (
//                   <>
//                     Complete The Course
//                     <span className="text-xl">✓</span>
//                   </>
//                 )}
//               </button>
//             </div>
//           </div>
//         ) : (
//           <div className="bg-white rounded-xl shadow-md p-6 text-center">
//             <p className="text-gray-600 text-lg">No questions available for this course.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Quiz;


import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Quiz data for different courses
const courseQuestions = {
  "101": {
    title: "CT Brain Plain Study Quiz",
    description: "Test your knowledge about CT brain plain study fundamentals",
    questions: [
      { 
        id: 1, 
        question: "Q1. What is the primary use of CT brain plain study?", 
        options: [
          "To detect intracranial haemorrhage and fractures",
          "To diagnose brain tumors",
          "To detect sinus infections",
          "To monitor migraine headaches"
        ]
      },
      { 
        id: 2, 
        question: "Q2. Why is contrast sometimes avoided in acute brain hemorrhage cases?", 
        options: [
          "Contrast enhances blood vessels, making fresh blood difficult to see",
          "Contrast is toxic for the brain",
          "Contrast is only used in MRI",
          "Contrast has no effect on CT scans"
        ]
      },
      { 
        id: 3, 
        question: "Q3. How does an acute hemorrhage appear on a CT brain plain study?", 
        options: [
          "Hyperdense (bright)",
          "Hypodense (dark)",
          "Isodense",
          "Not visible"
        ]
      },
      { 
        id: 4, 
        question: "Q4. What is the best imaging modality for detecting acute ischemic stroke?", 
        options: [
          "CT Brain Plain",
          "MRI DWI",
          "X-ray",
          "PET scan"
        ]
      },
      { 
        id: 5, 
        question: "Q5. What is a limitation of a CT brain plain study compared to MRI?", 
        options: [
          "It is more expensive",
          "It cannot detect skull fractures",
          "It exposes the patient to ionizing radiation",
          "It takes longer to perform"
        ]
      }
    ]
  },
  "102": {
    title: "Contrast-Enhanced CT Brain Quiz",
    description: "Evaluate your understanding of contrast-enhanced CT brain scans",
    questions: [
      { 
        id: 1, 
        question: "Q1. What is the primary indication for performing a contrast-enhanced CT brain scan?", 
        options: [
          "To evaluate brain tumors and metastases",
          "To detect acute hemorrhage",
          "To diagnose skull fractures",
          "To assess brain atrophy"
        ]
      },
      { 
        id: 2, 
        question: "Q2. What is the most likely diagnosis for this CT brain finding?", 
        options: [
          "Glioblastoma Multiforme",
          "Tuberculoma",
          "Meningioma",
          "Brain Abscess"
        ]
      },
      { 
        id: 3, 
        question: "Q3. Which condition is most commonly associated with this finding?", 
        options: [
          "Subarachnoid Haemorrhage",
          "Hypertensive Intracerebral Haemorrhage",
          "Cavernous Malformation",
          "Ischemic Stroke"
        ]
      },
      { 
        id: 4, 
        question: "Q4. Which of the following conditions typically shows enhancement on a contrast-enhanced CT brain scan?", 
        options: [
          "Meningitis",
          "Multiple Sclerosis",
          "Diffuse Axonal Injury",
          "Alzheimer's Disease"
        ]
      },
      { 
        id: 5, 
        question: "Q5. Which of the following is a contraindication for administering IV contrast in a CT brain scan?", 
        options: [
          "Suspected Brain Tumour",
          "History of Severe Contrast Allergy",
          "Evaluation of Brain Metastases",
          "Suspected Meningitis"
        ]
      }
    ]
  },
  "103": {
    title: "Clinical Case-Based CT Brain Quiz",
    description: "Test your diagnostic skills with real clinical scenarios",
    questions: [
      { 
        id: 1, 
        question: "Q1. A non-contrast CT brain scan shows a hypodense area in the left middle cerebral artery (MCA) territory with loss of gray-white matter differentiation. What is the most likely diagnosis?", 
        options: [
          "Ischemic Stroke",
          "Haemorrhagic Stroke",
          "Brain Tumor",
          "Normal Brain"
        ]
      },
      { 
        id: 2, 
        question: "Q2. A CT scan shows a hyperdense (bright) MCA sign on the right side without a clear infarct area yet. What does this indicate?", 
        options: [
          "MCA Thrombosis",
          "Normal Variation",
          "Subarachnoid Haemorrhage",
          "Meningioma"
        ]
      },
      { 
        id: 3, 
        question: "Q3. A 65-year-old hypertensive patient presents with sudden loss of consciousness. A CT scan shows a hyperdense area in the left basal ganglia with surrounding edema. What is the most likely diagnosis?", 
        options: [
          "Ischemic Stroke",
          "Haemorrhagic Stroke",
          "Epidural Hematoma",
          "Brain Abscess"
        ]
      },
      { 
        id: 4, 
        question: "Q4. A 72-year-old male presents with sudden dizziness, vomiting, and difficulty walking. CT scan reveals a hypodense area in the cerebellum with brainstem compression. What is the next best step?", 
        options: [
          "Observe and repeat CT in 24 hours",
          "Perform an MRI",
          "Neurosurgical evaluation for decompression",
          "Start Aspirin and Clopidogrel"
        ]
      },
      { 
        id: 5, 
        question: "Q5. A 78-year-old patient with a history of falls presents with confusion. CT scan shows a crescent-shaped hypodensity along the left hemisphere. What is the most likely diagnosis?", 
        options: [
          "Epidural Hematoma",
          "Subdural Hematoma",
          "Ischemic Stroke",
          "Brain Abscess"
        ]
      }
    ]
  }
};

const Quiz = () => {
  const { courseId } = useParams();
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const registrationId = location.state?.registration_id || "";

  // Get current course data
  const currentCourse = courseQuestions[courseId] || {
    title: "Quiz Not Available",
    description: "No quiz available for this course.",
    questions: []
  };

  const handleAnswerChange = (questionId, selectedOption) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: selectedOption
    }));
  };

  const handleSubmit = () => {
    // Check if all questions are answered
    const totalQuestions = currentCourse.questions.length;
    const answeredQuestions = Object.keys(answers).length;

    if (answeredQuestions < totalQuestions) {
      alert(`Please answer all questions before submitting. (${answeredQuestions}/${totalQuestions} answered)`);
      return;
    }

    setSubmitted(true);
    console.log(`Quiz Submitted for Course ${courseId}:`, answers);
    // Here you can add an API call to submit answers

    // Define topicId if it's needed (replace "default-topic" with your actual topicId)
    const topicId = "default-topic";

    setTimeout(() => {
      alert("Quiz completed successfully!");
      navigate(`/student-dashboard/courses/ongoing/${topicId}/protocols/quiz-result/${courseId}`, {
        state: { registration_id: registrationId }
      });
    }, 1000);
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen p-6">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="flex items-center mb-8">
          <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center mr-4">
            <span className="text-xl text-white">📝</span>
          </div>
          <div>
            <h1 className="text-3xl font-bold text-gray-800">{currentCourse.title}</h1>
            <p className="text-gray-600 mt-1">{currentCourse.description}</p>
          </div>
        </div>

        {/* Quiz Content */}
        {currentCourse.questions.length > 0 ? (
          <div className="space-y-6">
            {currentCourse.questions.map((q) => (
              <div key={q.id} className="bg-white rounded-xl shadow-md overflow-hidden">
                <div className="p-6">
                  <h2 className="text-lg font-semibold text-gray-800 mb-4">{q.question}</h2>
                  <div className="space-y-3">
                    {q.options.map((option, index) => (
                      <label 
                        key={index}
                        className={`flex items-center p-3 rounded-lg border transition-all cursor-pointer
                          ${answers[q.id] === option 
                            ? 'bg-blue-50 border-blue-500' 
                            : 'border-gray-200 hover:bg-gray-50'}`}
                      >
                        <input
                          type="radio"
                          name={`question-${q.id}`}
                          value={option}
                          checked={answers[q.id] === option}
                          onChange={() => handleAnswerChange(q.id, option)}
                          className="w-4 h-4 text-blue-600"
                        />
                        <span className="ml-3 text-gray-700">{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {/* Submit Button */}
            <div className="flex justify-end mt-8">
              <button
                onClick={handleSubmit}
                disabled={submitted}
                className={`px-6 py-3 rounded-lg font-medium flex items-center gap-2 transition-all
                  ${submitted 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg'}`}
              >
                {submitted ? (
                  <>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Submitting...
                  </>
                ) : (
                  <>
                    Complete The Course
                    <span className="text-xl">✓</span>
                  </>
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-md p-6 text-center">
            <p className="text-gray-600 text-lg">No questions available for this course.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;